import LocalStorage from './localStorageUtil';
import Routes from '../layouts/Routes';

export const isAuthenticated = () => {
    const token = LocalStorage.getFromLocalStorage('userId');
    const check = LocalStorage.getFromLocalStorage('firstTime');
    if (token == null) {
        return false;
    }
   
    if (token && check == 'false') {
        return true;
    }
    if (localStorage.getItem('inauthenticatednew')==='true') {
        return true;
    }
    
    return false;
};

export const isAmbAuthenticated = () => {
    const token = LocalStorage.getFromLocalStorage('ambassadorId');
    // const check = LocalStorage.getFromLocalStorage('firstTime');
    if (token == null) {
        return false;
    }
    if (token) {
        return true;
    }
    return false;
};


export const checkAuth = (nextState, replace) => {
    if (!isAuthenticated()) {
        replace({ pathname: Routes.LOGIN });
    }
}

export const checkAmbAuth = (nextState, replace) => {
    if (!isAmbAuthenticated()) {
        replace({ pathname: Routes.AMBASSADORLOGIN });
    }
}

export const checkForm = (nextState, replace) => {
    const token = LocalStorage.getFromLocalStorage('formStatus');
    if (token == 'true') {


    } else {
        replace({ pathname: '/home/profile' });
    }
}

export const checkAmbForm = (nextState, replace) => {
    const token = LocalStorage.getFromLocalStorage('formStatus');
    if (token == 'true') {


    } else {
        replace({ pathname: '/ambassador/ambassadorProfile' });
    }
}