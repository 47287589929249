// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
// import './css/index.scss';
import 'jquery';
// import 'bootstrap/dist/js/bootstrap';
// import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import AppRouter from './layouts/App.Router';
import reportWebVitals from './reportWebVitals';
// import './index.scss'
import './indexnew.scss'
//rewamp
import "react-datepicker/dist/react-datepicker.css";
// window.Buffer=window.Buffer || require("buffer").Buffer;
// Buffer.from("anything","base64");
import Bootstrap from './components/containers/Marketing/bootstrap5';
const root = ReactDOM.createRoot(document.getElementById('phocketApp'));
root.render(
  <React.StrictMode >
    <AppRouter />
 </React.StrictMode>
);
// import './css/website.scss'

// ReactDOM.render(
//   <React.StrictMode>
//     <AppRouter />
//   </React.StrictMode>,
//   document.getElementById('phocketApp')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
