import { combineReducers } from 'redux';

//import { loginReducer as login } from '../components/containers/Login/Login.reducers';
import { AdminReducer as CRMMenu } from '../components/containers/Admin/AdminReducer.component';
import { UserDetailReducer as allUserDetail } from '../components/containers/Admin/AdminReducer.component';
import { DuePaymentReducer as duePayment } from '../components/containers/Admin/AdminReducer.component';
import { DuePaymentEMIReducer as duePaymentEmi } from '../components/containers/Admin/AdminReducer.component';
import { UserDetail as userDetail } from '../components/containers/Dashboard/DashboardReducer.component';
import { NotificationReducer as notification } from '../components/containers/Dashboard/DashboardReducer.component';
import { crmNotificationReducer as crmNotification } from '../components/containers/Admin/AdminReducer.component';
import { IncomingPaymentReducer as incomingPayment } from '../components/containers/Admin/AdminReducer.component';
import { LatestDocUploadReducer as latestDocUpload } from '../components/containers/Admin/AdminReducer.component';
import { LEadByMobileNumber as leadByNumber } from '../components/containers/Admin/AdminReducer.component';
import { LoanApprovalLead as loanApprovalLead } from '../components/containers/Admin/AdminReducer.component';
import { loanApprovalLeadGiftCard as loanApprovalLeadGiftCard } from '../components/containers/Admin/AdminReducer.component';

import { LoanAddaLeads as loanAddaLeadData } from '../components/containers/Admin/AdminReducer.component';
import { LoanApprovalLeadByPriority as loanApprovalLeadByPriority } from '../components/containers/Admin/AdminReducer.component';
import { DisburseLead as disburseLeads } from '../components/containers/Admin/AdminReducer.component';
import { ImportantLead as importantLeads } from '../components/containers/Admin/AdminReducer.component';
import { AdminUserReducer as admin } from '../components/containers/Admin/AdminReducer.component';
import { RejectUserReducer as rejectList } from '../components/containers/Admin/AdminReducer.component';
import { StatusReducer as allStatus } from '../components/containers/Admin/AdminReducer.component';
import { ambasadorDetailsReducer as ambasadorDetails } from '../components/containers/Ambassador/AmbasadorReducer.component';
import { AllAmbassadorDataAdmin as allAmbassadorData } from '../components/containers/Admin/AdminReducer.component';
import { loyalityDetailsReducer as loyalityDetails } from '../components/containers/Admin/AdminReducer.component';
import { activityDataReducer as activityData } from '../components/containers/Admin/AdminReducer.component';
import { ambasadorAllDetailsReducer as ambassadorAllData } from '../components/containers/Ambassador/AmbasadorReducer.component';
import { MerchantDataReducer as merchantAllData } from '../components/containers/Merchant/MerchantReducer.component';
import { LeadManagementReducer as leadManagementData } from '../components/containers/Admin/AdminReducer.component';
import { creditDashBoardData as creditDashBoardDataValue  } from '../components/containers/Admin/AdminReducer.component';
import { LoanApprovalLeadOptimized as loanApprovalLeadOptimized } from '../components/containers/Admin/AdminReducer.component';
import { LeadManagementOptimized as leadManagementDataOptimized } from '../components/containers/Admin/AdminReducer.component';
import { SimilarProfileData as internalDedupeData } from '../components/containers/Admin/AdminReducer.component';

const reducer = (state = {}, action) => {

	switch (action.type) {

		case 'ADD_EVENTS':
			return {
				...state,
				events: [...action.events]
			};

		case 'LOGIN':

			return {
				...state
			};

		case 'prticipant':

			return {
				...state,
				data: [...action.data]
			}

		case 'LOGOUT':
			return {
				...state,
				userDetails: {}
			};
		default: return state;
	}
};

export default combineReducers({
	allUserDetail,
	duePayment,
	duePaymentEmi,
	CRMMenu,
	userDetail,
	notification,
	incomingPayment,
	latestDocUpload,
	leadByNumber,
	crmNotification,
	loanApprovalLead,
	loanApprovalLeadGiftCard,
	loanApprovalLeadByPriority,
	disburseLeads,
	importantLeads,
	admin,
	rejectList,
	allStatus,
	ambasadorDetails,
	allAmbassadorData,
	loyalityDetails,
	activityData,
	ambassadorAllData,
	merchantAllData,
	leadManagementData,
	loanAddaLeadData,
	creditDashBoardDataValue,
	loanApprovalLeadOptimized,
	leadManagementDataOptimized,
	internalDedupeData
	
});