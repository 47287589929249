


const saveInLocalStorage = (key, value) => {
    let strKey = key, 
        strValue = value;
    if(typeof key !== 'string') strKey = key + '';

    if(typeof value === 'object') strValue = JSON.stringify(value);

    window.localStorage.setItem(strKey, strValue);
};

const getFromLocalStorage = (key) => {
    let strKey = key;

    if(typeof key !== 'string') strKey = key + '';

    return window.localStorage.getItem(strKey);
};

const deleteFromLocalStorage = key => {
    let strKey = key;

    if(typeof key !== 'string') strKey = key + '';
    
    window.localStorage.removeItem(key);
  
};

export default {
    saveInLocalStorage, 
    getFromLocalStorage,
    deleteFromLocalStorage
};