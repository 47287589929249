import React from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonUi = () => {
    return (
       
            <div class="loader loading-container" style={{width:'100%',marginTop:'100px'}}>
                <div class="loading-text">
                    <span>L</span>
                    <span>O</span>
                    <span>A</span>
                    <span>D</span>
                    <span>I</span>
                    <span>N</span>
                    <span>G</span>
                </div>
            </div>
       
    )
}
export default SkeletonUi