import React from 'react'
import loadable from 'loadable-components';
import Loader from '../components/presentationals/Loader/Loader.component'

export const App = loadable(() => import('./App.Layout'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});

export const Home = loadable(() => import('./Home.layout'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});

export const Form = loadable(() => import('../components/containers/Forms/ApplicationForm.container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});

export const AdminLogin = loadable(() => import('../components/containers/Admin/Login.container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});

export const PROMOTIONALDATA = loadable(() => import('../components/containers/Admin/Promotions/PromotionalData.container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});

export const Login = loadable(() => import('../components/containers/Dashboard/Login.container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});

export const Dashboard = loadable(() => import('../components/containers/Dashboard/Dashboard.container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});

export const Feedback = loadable(() => import('../components/containers/Admin/CustomerPages/UserFeedback.container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});

export const VerificationPage = loadable(() => import('../components/containers/Home/ResponseForVerification.container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});

export const AmbassadorLogin = loadable(() => import('../components/containers/Ambassador/Login.container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});

export const AmbForm = loadable(() => import('../components/containers/Ambassador/Form.container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});

export const mobielFaq = loadable(() => import('../components/containers/Home/MobileAppFAQ.container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});

export const AmbDashboard = loadable(() => import('../components/containers/Ambassador/Dashboard.container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});

export const couponNew = loadable(() => import('../components/containers/Admin/AdminCoupons/CouponReporting.Container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});

export const calc = loadable(() => import('../components/containers/Calc/MainCalculator.container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});

export const calculator = loadable(() => import('../components/containers/Calc/Calculator.container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});

export const EmiCalc = loadable(() => import('../components/containers/Calc/EMICalculator.container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});

export const MerRegistration = loadable(() => import('../components/containers/Merchant/Registration.container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});

export const MerchantDashboard = loadable(() => import('../components/containers/Merchant/MerchantDashboard.container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});

export const MerchantLogin = loadable(() => import('../components/containers/Merchant/MerchantLogin'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});

export const UPLOAD_DOCUMENT = loadable(() => import('../components/containers/Admin/UploadDocumentMail.container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});


export const CAMPAIGNTRACK = loadable(() => import('../components/containers/campaigntrack/AppDownload.Container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});

export const THIRDPARTYLOGIN = loadable(() => import('../components/containers/campaigntrack/Login.Container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});

export const THIRDPARTYREGISTRATIONS = loadable(() => import('../components/containers/campaigntrack/Registration.Container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});

export const APPLICANTREGISTRATION = loadable(() => import('../components/presentationals/Ambassador/FormApplicantDetail.component'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});

export const WEBBLOG = loadable(() => import('../components/presentationals/WebRelatedPages/Blog.component'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});

export const ENACHPAGE = loadable(() => import('../components/containers/Home/Enach.container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});
export const TECHPROCESS = loadable(() => import('../components/containers/Home/TechProcess.container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});

export const ESIGNPAGE = loadable(() => import('../components/containers/Home/NewEsign.container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});

// export const NBFCDASHBOARD = loadable(() => import('../components/containers/nbfc/Mainpage.container'), {
//   render: ({ Component, loading, ownProps }) => {
//     if (loading) return <Loader />
//     return <Component {...ownProps} />
//   },
// });

// export const NBFCLOGIN = loadable(() => import('../components/containers/nbfc/NbfcLogin.container'), {
//   render: ({ Component, loading, ownProps }) => {
//     if (loading) return <Loader />
//     return <Component {...ownProps} />
//   },
// });

export const UNSUBSCRIBE_PAGE = loadable(() => import('../components/containers/Home/Unsubscribe.container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});

export const analysis = loadable(() => import('../components/containers/Admin/Analysis/ProfileAnalysisNew.container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});

export const consent = loadable(() => import('../components/containers/facebookConsent/consentPage.container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});
export const Reference = loadable(() => import('../components/containers/Forms/UserReference.container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});
export const BadaBazaar = loadable(() => import('../components/containers/BadaBazaar/login.container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});
export const PoratlDashboard = loadable(() => import('../components/containers/BadaBazaar/MainPage.container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});
export const EsignMobile = loadable(() => import('../components/containers/Home/EsignIfram.container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});
export const EnachMobile = loadable(() => import('../components/containers/Home/EnachIframe.container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});

export const RazorpayPayment = loadable(() => import('../components/containers/Home/PaymentRazorPay.container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});



export const EnachCardPage = loadable(() => import('../components/containers/Admin/CardsUtility/CardEnach And Esign/Enach.container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});
export const EsignCardPage = loadable(() => import('../components/containers/Admin/CardsUtility/CardEnach And Esign/NewEsign.container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});
export const TEAGARDEN_LOGIN = loadable(() => import('../components/containers/TeaGarden/Login.container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});
export const TEAGARDEN_FORM = loadable(() => import('../components/containers/TeaGarden/NewForm.container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});
export const TEA_AMBASSADOR_PAGE = loadable(() => import('../components/containers/TeaGarden/AmbassadorTeaGarden/Login.container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});
export const TEA_AMBASSADOR_DASHBOARD_PAGE = loadable(() => import('../components/containers/TeaGarden/AmbassadorTeaGarden/Dashboard.container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});
export const SAVE_BANK_DETAILS_URL = loadable(() => import('../components/containers/Forms/saveBankDetailsIframe.container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});
export const ENACH_BILL_DESK = loadable(() => import('../components/containers/Home/EnachBillDesk.container'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});

export const SEIGNDESK_REDIRECT_URL = loadable(() => import('../components/containers/Home/signdeskRedirectPage'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});
export const KYC_MOBILE = loadable(() => import('../components/containers/Home/kycMobile'), {
  render: ({ Component, loading, ownProps }) => {
    if (loading) return <Loader />
    return <Component {...ownProps} />
  },
});