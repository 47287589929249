import { WEBBLOG } from "./Routers";

export default {
    FEEDBACK: '/feedback/:userId',
    RESPONSEFORVERIFICATION: '/mailAuth/:emailId/:code',
    CALC: '/calc',
    CALCULATOR: '/calculator',
    EMI_CALCULATOR: '/emiCalculator',
    FORM: 'form',
    ADMINLOGIN: '/adminLogin',
    PROMOTIONALDATA: '/promotionalData',
    LOGIN: '/login',
    HOME: '/home',
    DASHBOARD: 'profile',
    TESTING: 'test',
    ENACHANDESIGN: '/enachAndEsign',
    UPLOAD_DOCUMENT: '/uploadDocuments/:userId',
    MOBILE_FAQ: '/mobileFAQ',
    AMBASSADORLOGIN: '/ambassadorLogin',
    AMB_FORM: 'form',
    AMBASSADOR: '/ambassador',
    AMBDASHBOARD: 'ambassadorProfile',
    COUPONSNEW: '/couponNew',

    MERCHANT_LOGIN: '/MerchantLogin',
    MERCHANT_REGISTRATION: '/merchantRegistration',
    MERCHANT_DASHBOARD: '/merchantHome',
    CAMPAIGNTRACK: '/campaigntrack',
    THIRDPARTYLOGIN: '/thirdpartylogin',
    THIRDPARTYREGISTRATIONS: '/thirdpartyregistration',
    APPLICANTREGISTRATION: '/applicantregistartion',
    E_NACH_PAGE: '/enach/:loanId',
    E_SIGN_PAGE: '/esign/:loanId',
    E_SIGN_NACH_PAGE: '/esignEnach/:loanId',
    WEBBLOG: '/blogUpload',
    NBFCDASHBOARD: '/nbfcdashboard',
    NBFCLOGIN: '/nbfclogin',
    UNSUBSCRIBE_PAGE: '/unsubscribe/:userId',
    ANALYSIS: '/analysis/:loanId',
    TECHPROCESS: '/techprocess/:loanId',
    CONSENT: '/consent/:userId',
    Reference: '/userReference/:userId',
    BADABAZAR_LOGIN: '/badabuisnessLogin',
    PORTAL_DASHBOARD: '/portalDashboard',
    E_NACH_PAGE_MOBILE: '/enachMobile/:loanId',
    E_SIGN_PAGE_MOBILE: '/esignMobile/:loanId',
    // PAYMENT_RAZORPAY_URL: '/payment/:orderId',
    E_NACH_PAGE_CARD: '/enachCard/:userId',
    E_SIGN_PAGE_CARD: '/esignCard/:userId',
    // TeaGardenUrl

    CUSRTOMER_LOGIN: '/customerLogin',
    CUSRTOMER_FORM: '/customerForm',
    TEA_AMBASSADOR: '/teaambassadorlogin',
    TEA_AMBASSADOR_DASHBOARD: '/dashboard',
    AMBASSADOR_CUSTOMER_FLOW: '/customerForm/:id',
    SAVE_BANK_DETAILS_URL: '/savebankdetails/:userId',
    ENACH_BILL_DESK:'/emandate/:loanId',
    BILL_PAYMENT:'/payment/:loanId',
    BUISNESS_URL:'/buisnessreport',
    DEMO:'/demo',
    MOBILE_EMANDATE_BILLDESK:'/memandate/:loanId',
    MOBILE_PAY_BILLDESK:'/mpay/:loanId',
    FIDYPAY_MANDATE:'/fmandate/:loanId',
    MARKETING_FORM:'/marketing',
    ENACH_SIGNDESK:'/smandate/:loanId',
    ENACH_SIGNDESK_MOBILE:'/mandatemobile/:loanId',
    SEIGNDESK_REDIRECT_URL:'/signdeskmandate',
    KYC_FORM:'/kyc/:userId',
    KYC_MOBILE:'/kycMobile/:userId',
    FETCHMAIL:'/maillogin'








};