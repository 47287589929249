import React, { useEffect } from 'react'
import Logo from './Alert.png'
class Alert extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataMsg: ''
        }
    }
    UNSAFE_componentWillReceiveProps() {
        
        if (this.props.location.state !== undefined && this.props.location.state.data !== undefined) {
            this.setState({ dataMsg: this.props.location.state.data })
        }
    }
    componentDidMount(){
        if (this.props.location.state !== undefined && this.props.location.state.data !== undefined) {
            this.setState({ dataMsg: this.props.location.state.data })
        }
    }
    render() {
        return (
            <div className="container-fluid">
                <div className=''>
                    <div className='col-sm-2 col-xs-12'></div>
                    <div className='col-sm-8 col-xs-12 text-center' >
                        <div className='center-part-alert'>
                            <div className='alert-image'>
                                <img src={Logo} />
                            </div>
                            <h3 style={{ marginTop: '70px' }}><b>Something went wrong!</b></h3>
                            <h4 style={{ marginTop: '70px' }}>{this.state.dataMsg}</h4>
                        </div>
                    </div>
                    <div className='col-sm-2 col-xs-12'></div>
                </div>
            </div>
        )
    }
}

export default Alert